<template>
    <overlay :show="modal" z-index="999" >
        <div class="container">

            <!--        <Qr :size="400" :text="url"></Qr>-->
<!--            <vue-qr :text="url" :size="400" class="qr-img" :logoSrc="imageUrl"></vue-qr>-->
            <div id="code">

                <canvas id="canvas" ></canvas>

            </div>
            <div class="qr-txt">请长按保存图片或者截图保存后去微信->扫一扫打开</div>
        </div>
    </overlay>
</template>
<script>
    //import Qr from "@/view/components/qr"
    // import {getOrigin} from "@/utils/common"
    import {weiXinInner} from "@/utils/common"
    import {Overlay} from "vant"
    import QRCode from 'qrcode'
    export default {
        components:{
            //Qr
          //  QRcode:QRCode ,//注册组件
            Overlay
        },
        props:{
            url:{
                type:String,
                default:''
            }
        },
        data(){
            return {
               // url:getOrigin+"/elecricity/index",
                imageUrl: require("@/assets/logo.png"),
                modal:false
            }
        },
        mounted() {
            if(!weiXinInner()){

                this.modal=true
                this.getUrl(this.url);
            }
        },
        methods:{
            getUrl(xxx){
                var canvas=document.getElementById("canvas"); //获取到canvas
                var code=document.getElementById("code");   //获取到code容器
                 QRCode.toCanvas(canvas,xxx, error=> {
                     if (error) console.error(error);
                     console.log("success!");              });
                 var image = new Image();     //实例一个img
                 image.src = canvas.toDataURL("image/png");  //转换成base64格式路径的png图片
                      image.style.width = "100%";    //设置样式
                 code.appendChild(image);     //添加到code 容器中
                 canvas.style.display = "none";   //隐藏掉canvas
                }


        }
    }
</script>
<!--<style>-->
<!--    html,body,#app,.container{-->
<!--        height: 100%;-->
<!--    }-->
<!--</style>-->
<style scoped>

    .container{
        /*background: #fff;*/
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .qr-img{
        display: block!important;
        margin: 0 auto;
        width: 12rem;
    }
    #code{
        width: 12rem;
    }
    .qr-txt{
        margin: 0.5rem auto;
        width: 11rem;
        color: #fff;
        text-align: center;
        font-size: 0.8rem;
    }
</style>
